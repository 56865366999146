<template>
  <div
      class="flex flex-col text-a-neutral bg-a-main-gray relative font-sans tracking-wide"
  >
    <NewsNav class="hidden md:flex"/>
    <NewsNavMobile class="flex md:hidden"/>
    <MainSideMenuMobile class="flex md:hidden"/>
    <div class="flex flex-col items-center justify-start w-full">
      <slot/>
    </div>
  </div>
</template>
<script setup>
</script>